import { useMemo } from "react";
import PropTypes from 'prop-types';

const fromMap = {

    tt : "Test",
    am: "FB AstroMisie",
    rp : "FB React Polska",
    jsp : "FB Javascript Polska",
    php : "FB PHP",
    red: "FB React developers",
    ga: "FB Geeks Academy",
    jsa: "FB Javascript Developers Australia",
    srjrn: "FB ReactJs, React Native",
    sr: "FB Szkoła Reacta",
    rja: "r/javascrtipt",
    rra: "r/react",
    rsj: "r/reactjs",
    rsp: "r/sideproject",
    rwd: "r/webdev",
    rna: "r/nasa",
    rma: "r/mars",
    stw: "Twitter",
    scs: "FB Chatscope",
    spp: "FB Moje",
    ytw: "YT Wieczorek"
    
};

export const RefDesc = ({referrer}) => {
    
    const from = useMemo( () => {
        
        return fromMap[referrer] || "Unknown";
        
    },[referrer]); 
    
    return (
        <div>
            From: {from}
        </div>
    );
};

RefDesc.propTypes = {
    referrer: PropTypes.string
};

RefDesc.defaultProps = {
    referrer: "dd"
};

export default RefDesc;