import React,{useState, useRef, useMemo} from "react";
import PropTypes from "prop-types";
import {MainContainer, Sidebar, ConversationList, Conversation, ChatContainer, ConversationHeader, Message, MessageList, Avatar, MessageInput} from "@chatscope/chat-ui-kit-react";
import remoteAvatar from "./joe.svg";
import perseveranceAvatar from "../assets/perseveranceAvatar.png";
import RefDesc from "./RefDesc";


export const Widget = ({messages, users, onSend, onConversationChange, activeConversation, perserverance}) => {
    
    const inputRef = useRef();
    const [message,setMessage] = useState("");
    
    const handleChange = msg => setMessage(msg);

    const handleSend = value => {
        setMessage("");
        onSend(value);
        inputRef.current.focus();
    };

    return <div className="d-flex flex-column position-absolute" style={{top:0,left:0,right:0, bottom:0}}>
        <MainContainer style={{fontSize:"1em"}} responsive={true}>
            <Sidebar position="left" scrollable={false}>
                <ConversationList>
                    {users.map( u => <Conversation key={u.id}
                                                   active={activeConversation?.id === u.id}
                                                   onClick={() => onConversationChange(u)}>
                        {u.name.match(/perseverance-\.*/i) ? <Avatar src={perseveranceAvatar} /> : <Avatar src={remoteAvatar} />}
                        <Conversation.Content>
                            <div>{u.name}</div>
                            <RefDesc referrer={u.props?.ref} />
                            <div>IP: {u.props?.ip}</div>
                        </Conversation.Content>
                        
                    </Conversation>)}
                </ConversationList>
            </Sidebar>
            <ChatContainer>

                <ConversationHeader>
                    {activeConversation?.name.match(/perseverance-\.*/i) ? <Avatar src={perseveranceAvatar} /> : <Avatar src={remoteAvatar} />}
                    <ConversationHeader.Content userName={activeConversation?.name??"unknown"} info="" />
                </ConversationHeader>

                <MessageList>

                    {activeConversation && activeConversation.id && messages[activeConversation.id] && messages[activeConversation.id].map( (m,i) => <Message key={i} model={m} />) }

                </MessageList>
                <MessageInput ref={inputRef} placeholder="Type message here" attachButton={false} onSend={handleSend}  onChange={handleChange} autoFocus={true} value={message}
                    disabled={!activeConversation}/>
            </ChatContainer>
        </MainContainer>
    </div>;
};

Widget.propTypes =  {
    messages: PropTypes.object,
    users: PropTypes.array,
    peserverance: PropTypes.bool,
    onSend: PropTypes.func,
    onConversationChange: PropTypes.func,
    activeConversation: PropTypes.object
};

Widget.defaultProps = {
    messages: {},
    users: [],
    perserverance: false,
    onSend: () => {},
    onConversationChange: () => {}
};

export default Widget;
