import React, {useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./app.scss";
import io from 'socket.io-client';
import Widget from "./components/Widget";
import { useImmer } from "use-immer";

export const App = () => {

  const socketRef = useRef();

  const [messages, setMessages] = useImmer({});
  const [users, setUsers] = useImmer([]);
  const [activeConversation, setActiveConversation] = useState();

  useEffect(() => {

    // Dev
    let serverAddress = "http://localhost:3000";
    if (process.env.NODE_ENV === 'production') {
      serverAddress = "https://w-api.chatscope.io";
    }
    
    // Production
    const socket = io( serverAddress,{
      transports: ['websocket']
    });

    socket.on("connect", () => {
      console.log("Connected");
      socket.emit("adminregister", "yeah");
      socketRef.current = socket;
    });

    socket.on("message", (from, msg) => {
      console.log("Received message from: " + from, msg);

      const newMessage = {
        message: msg,
        sentTime: "now",
        sender: "chatscope",
        direction: "incoming",
        position: "single"
      };

      setMessages(msgs => {

        const newMessages =  from in msgs ? msgs[from].concat(newMessage) : [newMessage];

        return {...msgs, [from]: newMessages };

      });

    });

    socket.on("user-connected", user => {
      console.log("User connected", user);
      
      setUsers( us => {

        // Jeżeli już taki istnieje to podmieniam - to jest generalnie lekarstwo na disconnecty tego samego usera
        const existedIdx = us.findIndex( u => {
          return u.id === user.id
        } );

        if ( existedIdx !== -1 ) {
          return us.slice(0,existedIdx).concat(user).concat(us.slice(existedIdx+1));
        } else {
          return us.concat(user);
        }
        
      });
    });

    socket.on("user-disconnected", user => {
      console.log("User disconnected", user);
      setUsers( us => {
        const removed = us.filter( u => u.name !== user.name);
        console.log("Removed users", removed);
        if ( removed.length === 0 ) {
          setActiveConversation(undefined);
        }
        return removed;
      })
    });

  },[setMessages, setUsers]);

  const handleSend = msg => {

    const newMessage = {
      message: msg,
      sentTime: "now",
      sender: "me",
      direction: "outgoing",
      position: "single"
    };

    setMessages( msgs => {

      const newMessages =  activeConversation.id in msgs ? msgs[activeConversation.id].concat(newMessage) : [newMessage];

      return { ...msgs, [activeConversation.id]: newMessages };
    });

    socketRef.current.emit("direct-message", activeConversation.id, msg);

  };


  const handleConversationChange = (conversation)=> {

    console.log("Conversation change to: " + conversation.id);
    setActiveConversation(conversation);

  };

  return <div className="position-relative h-100 w-100">
    <Widget messages={messages} users={users} onSend={handleSend} onConversationChange={handleConversationChange}
            activeConversation={activeConversation}
    />
  </div>;

};

export default App;
